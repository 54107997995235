/** @format */

import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';
import { useAuth } from 'src/contexts/AuthContext';
import { Redirect, useHistory } from 'react-router';
import { Form, Input, Button, Checkbox, Row, Col } from 'antd';
const Login = props => {
  const { login, currentUser } = useAuth();
  const history = useHistory();

  const onFinish = async values => {
    try {
      await login(values.username, values.password);
      history.push('/recording');
    } catch (err) {
      console.log(err);
    }
  };

  const onFinishFailed = errorInfo => {
    console.log('Failed:', errorInfo);
  };

  const LoginWraper = styled.section`
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    margin-top: 10rem;
    padding: 1rem;
    @media (min-width: 768px) {
      padding: 3rem;
    }
  `;
  const LoginTitle = styled.h1`
    font-size: 5rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 3rem;
  `;
  return !currentUser ? (
    <LoginWraper>
      <LoginTitle>Login Money</LoginTitle>
      <Row justify="start">
        <Helmet>
          <title>Login | Money</title>
        </Helmet>
        <Col span={24}>
          <Form
            name="basic"
            initialValues={{
              remember: true
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: 'Please input your username!'
                }
              ]}
            >
              <Input placeholder="username" />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: 'Please input your password!'
                }
              ]}
            >
              <Input.Password placeholder="password" />
            </Form.Item>

            <Form.Item
              name="remember"
              valuePropName="checked"
              wrapperCol={{
                offset: 0,
                span: 16
              }}
            >
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" size="large" block>
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </LoginWraper>
  ) : (
    <Redirect to={'/recording'} exact />
  );
};

export default Login;
