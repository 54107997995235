/** @format */

import React, { useState } from 'react';
import { Form, Input, Button, Cascader, DatePicker, InputNumber, Card, notification, Switch } from 'antd';
import { actionOption, payBarcode } from 'src/config';
import moment from 'moment';
import { useDB } from 'src/contexts/DBContext';

const FormAdd = props => {
  const { addData } = useDB();

  const [addList] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(moment().add(1, 'Month').startOf('month'));
  const onDateChange = (date, dateString) => {
    setDate(date);
  };
  const onReset = () => {
    addList.resetFields();
  };

  const onFinish = async values => {
    setLoading(true);
    let data = {
      list: values.title,
      action: values.catagory,
      create_date: moment().format('YYYY-MM-DD, H:mm'),
      create_month: moment(date).format('YYYY-MM'),
      update_date: moment(date).format('YYYY-MM-DD, H:mm'),
      amount: values.amount,
      status: values.status || false
    };
    try {
      let addData_list = await addData('lists', data);
      if (addData_list === 1) {
        onReset();
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <Card title="บันทักรายรับ รายจ่าย" loading={loading} className="record-box">
        <Form
          labelCol={{
            span: 4
          }}
          wrapperCol={{
            span: 14
          }}
          layout="horizontal"
          size={'large'}
          form={addList}
          onFinish={onFinish}>
          <Form.Item
            label="รายการบันทึก"
            name="title"
            rules={[
              {
                required: true,
                message: 'ใส่ข้อความ'
              }
            ]}>
            <Input />
          </Form.Item>
          <Form.Item
            label="หมวดหมู่"
            name="catagory"
            rules={[
              {
                required: true,
                message: 'ใส่ข้อความ'
              }
            ]}>
            <Cascader options={actionOption} />
          </Form.Item>
          <Form.Item
            label="เลือกวันที่"
            name="date"
            rules={[
              {
                required: false,
                message: 'ใส่ข้อความ'
              }
            ]}>
            <DatePicker onChange={onDateChange} defaultValue={date} style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            label="จำนวน"
            name="amount"
            rules={[
              {
                required: true,
                message: 'ใส่ข้อความ'
              }
            ]}>
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item label="สถานะ" name="status">
            <Switch />
          </Form.Item>
          <Form.Item label="กด">
            <Button htmlType="submit" type="primary" block>
              บันทึก
            </Button>
          </Form.Item>
        </Form>

        {/* <div className="pay-barcode">
          {payBarcode.map((card, i) => {
            return (
              <div className="pay-barcord-item" key={i}>
                <img src={card.value} alt="" />
                <span>{card.title}</span>
              </div>
            );
          })}
        </div> */}
      </Card>
    </>
  );
};

export default FormAdd;
