
import React, { createContext, useContext, useState, useEffect } from 'react';
import { firebaseStore } from 'src/apis/firebase';
import { useDispatch } from 'react-redux';
import { GetListsAction } from 'src/store/action';
import { GetSettingAction } from 'src/store/action/getSettingAction';
import { GetUsuallyListAction } from 'src/store/action/getUsuallyListAction';
import { notification } from 'antd';

const  DBContext = createContext();
export const useDB = () => {
    return useContext( DBContext );
};
export const DBProvider = ({children}) =>{
    const dispatch = useDispatch();

    useEffect(() => {
        const initialData = getData();
        const initailSetting = getSetting();
        const initailUsuallyList = getUsuallyList();
        return initialData, initailSetting, initailUsuallyList;
      }, []);

      const getData = async () => {
        await firebaseStore
          .collection('lists')
          .get()
          .then(item => {
            let items_lists = item.docs.map(doc => ({
              key: doc.id,
              ...doc.data()
            }));
            // localStorage.setItem('dataLists', JSON.stringify(items));
            dispatch(GetListsAction(items_lists));
          });
      };
      const getSetting = () => {
        firebaseStore
          .collection('setting')
          .get()
          .then(item => {
            let items_setting = item.docs.map(doc => ({
              key: doc.id,
              ...doc.data()
            }));
            dispatch(GetSettingAction(items_setting));
          });
      };
      const getUsuallyList = () => {
        firebaseStore
          .collection('usually_list')
          .get()
          .then(item => {
            let items_setting = item.docs.map(doc => ({
              key: doc.id,
              ...doc.data()
            }));
            dispatch(GetUsuallyListAction(items_setting));
          });
      };
      const deleteData = (collection, key) => {
        firebaseStore
          .collection(collection)
          .doc(key)
          .delete()
          .then(() => {
            if (collection === 'lists') {
              getData();
            }
            if (collection === 'setting') {
              getSetting();
            }
            if (collection === 'usually_list') {
              getUsuallyList();
            }
            notification.open({
              message: 'Success!',
              description: 'Data have been delete!',
              onClick: () => {}
            });
            return 1;
          });
      };
      const updateData = (collection, key, data) => {
        firebaseStore
          .collection(collection)
          .doc(key)
          .update(data)
          .then(() => {
            if (collection === 'lists') {
              getData();
            }
            if (collection === 'setting') {
              getSetting();
            }
            if (collection === 'usually_list') {
              getUsuallyList();
            }
            notification.open({
              message: 'Success!',
              description: 'Data have been Save!',
              onClick: () => {}
            });
            return 1;
          })
          .catch(err => {
            console.log(err);
          });
      };
      const addData = (collection, data) => {
        return firebaseStore
          .collection(collection)
          .add(data)
          .then(res => {
            if (collection === 'lists') {
              getData();
            }
            if (collection === 'setting') {
              getSetting();
            }
            if (collection === 'usually_list') {
              getUsuallyList();
            }
            notification.open({
              message: 'Success!',
              description: 'Data have been Save!',
              onClick: () => {}
            });
            return 1;
          })
          .catch(err => {
            console.log(err);
          });
      };

    const value = {
        getUsuallyList,
        getData,
        getSetting,
        updateData,
        deleteData,
        addData
      };
    return <DBContext.Provider value={value}>{children}</DBContext.Provider>;

}