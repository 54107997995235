/** @format */

import React, { createContext, useContext, useState, useEffect } from 'react';
import { auth } from 'src/apis/firebase';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {

  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  const signup = (email, password) => {
    return auth.createUserWithEmailAndPassword(email, password);
  };
  const login = (email, password) => {
    return auth.signInWithEmailAndPassword(email, password);
  };
  const logout = () => {
    return auth.signOut();
  };
  const resetPassword = email => {
    return auth.sendPasswordResetEmail(email);
  };
  const updateEmail = email => {
    return currentUser.updateEmail(email);
  };
  const updatePassword = password => {
    return currentUser.updatePassword(password);
  };
  useEffect(() => {
    const unsbscribe = auth.onAuthStateChanged(user => {
      setCurrentUser(user);
      setLoading(false);
    });
   
    return unsbscribe;
  }, []);
  
  const value = {
    currentUser,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword
  };
  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
};
