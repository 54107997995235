/** @format */

const GetUsuallyListAction = data => {
    return {
      type: 'GETUSUALLYLIST',
      playload: data
    };
  };
  
  export { GetUsuallyListAction };
  