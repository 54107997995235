/** @format */

import React, { useEffect, useState } from 'react';
import {
  Table,
  Button,
  Cascader,
  DatePicker,
  Input,
  InputNumber,
  Popconfirm,
  Form,
  Typography,
  Space,
  Switch,
  PageHeader,
  Descriptions,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import Summary from 'src/components/Summary';
import { EditFilled, DeleteFilled, SaveFilled, CloseCircleFilled } from '@ant-design/icons';
import { allAcitionOption, actionOption } from 'src/config';
import { useDB } from 'src/contexts/DBContext';
import NumberFormat from 'react-number-format';

const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
  const inputNode = inputType => {
    if (inputType === 'select') {
      return <Cascader options={actionOption} />;
    }
    if (inputType === 'number') {
      return <InputNumber />;
    }
    return <Input />;
  };

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`
            }
          ]}>
          {inputNode(inputType)}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const GetUsualList = props => {
  const { getUsuallyList, deleteData, updateData } = useDB();
  const dataFromState = useSelector(stage => stage.getUsuallyList);
  const [allTotal, setAllTotal] = useState({});
  //table form
  const [form] = Form.useForm();
  const [data, setData] = useState(null);
  const [editingKey, setEditingKey] = useState('');

  useEffect(() => {
    handleSetData(dataFromState);
  }, [dataFromState]);
  const handleSetData = data => {
    setData(data);
  };
  const deleteList = item => {
    deleteData('usually_list', item);
  };

  const isEditing = record => record.key === editingKey;

  const edit = record => {
    form.setFieldsValue({
      list: '',
      action: '',
      amount: '',
      ...record
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async key => {
    try {
      const row = await form.validateFields();
      updateData('usually_list', key, row);
      setEditingKey('');
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const handleChangeStatus = (status, data) => {
    let newStatus = { ...data, status: status };
    try {
      updateData('usually_list', data.key, newStatus);
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const columns = [
    {
      title: 'รายการ',
      dataIndex: 'list',
      width: '30%',
      editable: true
    },
    {
      title: 'วิธี',
      dataIndex: 'action',
      width: '15%',
      editable: true,
      render: (action, record) => {
        return action.map(item => {
          return allAcitionOption.map(card => {
            if (item === card.value) {
              return <span style={{ color: `${record.status ? 'green' : ''}` }}>{card.label + '  '}</span>;
            }
          });
        });
      }
    },
    {
      title: 'จำนวน /บาท',
      dataIndex: 'amount',
      width: '10%',
      editable: true,
      sorter: (a, b) => a.amount - b.amount,
      render: (text, record) => {
        if (record.action[0] === 'pay') {
          return (
            <span style={{ color: 'red' }}>
              <NumberFormat value={record.amount} displayType={'text'} thousandSeparator={true} />
            </span>
          );
        }
        if (record.action[0] === 'income') {
          return (
            <span style={{ color: 'green' }}>
              <NumberFormat value={record.amount} displayType={'text'} thousandSeparator={true} />
            </span>
          );
        }
        if (record.action[0] === 'paycard') {
          return (
            <span style={{ color: 'orange' }}>
              <NumberFormat value={record.amount} displayType={'text'} thousandSeparator={true} />
            </span>
          );
        }

        return (
          <span style={{ color: 'red' }}>
            <NumberFormat value={record.amount} displayType={'text'} thousandSeparator={true} />
          </span>
        );
      }
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',

      render: (_, record) => {
        return (
          <>
            <Switch checked={record.status} onChange={e => handleChangeStatus(e, record)} />
          </>
        );
      }
    },
    {
      title: 'จัดการ',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <a
              href="javascript:;"
              onClick={() => save(record.key)}
              style={{
                marginRight: 8
              }}>
              <SaveFilled />
            </a>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>
                <CloseCircleFilled />
              </a>
            </Popconfirm>
          </span>
        ) : (
          <Space>
            <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
              <EditFilled />
            </Typography.Link>
            <Popconfirm disabled={editingKey !== ''} title="Sure to delete?" onConfirm={() => deleteList(record.key)}>
              <a disabled={editingKey !== ''}>
                <DeleteFilled />
              </a>
            </Popconfirm>
          </Space>
        );
      }
    }
  ];
  function checkInputType(dataIndex) {
    if (dataIndex === 'amount') {
      return 'number';
    }
    if (dataIndex === 'action') {
      return 'select';
    }
    if (dataIndex === 'update_date') {
      return 'date';
    }
    return 'text';
  }
  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: record => ({
        record,
        inputType: checkInputType(col.dataIndex),
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record)
      })
    };
  });
  function handleCalculate() {
    let total_income = 0;
    let total_coust = 0;
    let total_citi = 0;
    let total_ktc = 0;
    data &&  data.map(item => {

        if (item.action[0] === 'income') {
            total_income = total_income + parseFloat(item.amount);
          }
        if (item.action[0] === 'pay' || item.action[0] === 'paydebt') {
            total_coust = total_coust + parseFloat(item.amount);
          }
    })
    setAllTotal({
        income: total_income,
        coust: total_coust,
        citiCard: total_citi,
        ktcCard: total_ktc
      });
    }
  useEffect(() => {
    handleCalculate();
  }, [data]);
  return (
    <>
      <Button type="primary" onClick={() => handleSetData(dataFromState)}>
        Fetch
      </Button>
      <div
        className="site-page-header-ghost-wrapper"
        style={{
          border: '1px solid #f0f0f0',
          borderBottom: 'none'
        }}>
        <PageHeader
          ghost={false}
          title={`รายจ่ายประจำ `}
          subTitle={`${moment(props.target).format('MMMM YYYY')}`}
          >
          <Descriptions size="middle" column={3}>
            <Descriptions.Item label="รายรับ">
              <NumberFormat value={allTotal.income} displayType={'text'} thousandSeparator={true} />
            </Descriptions.Item>
            <Descriptions.Item label="รายจ่าย">
              <NumberFormat value={allTotal.coust} displayType={'text'} thousandSeparator={true} />
            </Descriptions.Item>
            <Descriptions.Item label="คงเหลือ">
              <NumberFormat value={allTotal.income - allTotal.coust} displayType={'text'} thousandSeparator={true} />
            </Descriptions.Item>
          </Descriptions>
          {/* <p>บัตรเครติด : </p>
          <Descriptions size="middle" column={3}>
            <Descriptions.Item label="Citi">{allTotal.citiCard}</Descriptions.Item>
            <Descriptions.Item label="KTC visa">{allTotal.ktcCard}</Descriptions.Item>
          </Descriptions> */}
        </PageHeader>
      </div>

      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell
            }
          }}
          loading={data ? false : true}
          bordered
          row={30}
          dataSource={data || null}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{ pageSize: 50 }}
        />
      </Form>
    </>
  );
};

export default GetUsualList;
