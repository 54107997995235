/** @format */

import { Card } from 'antd';
import React, { useEffect } from 'react';
import Layout from 'src/components/Layout';

const Dashboard = props => {
  useEffect(() => {}, []);
  return <Layout pageTitle="Dashboard | Money"></Layout>;
};

export default Dashboard;
