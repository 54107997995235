/** @format */

import { combineReducers } from 'redux';
import getListsReducer from './getListReducer';
import getSettingReducer from './getSettingReducer';
import getUsuallyListReducer from './getUsuallyListReducer';

const allReducers = combineReducers({
  getLists: getListsReducer,
  getSetting: getSettingReducer,
  getUsuallyList:getUsuallyListReducer,
});

export { allReducers };
