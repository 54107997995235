/** @format */

import React from 'react';
import ReactDOM from 'react-dom';
import 'antd/dist/antd.css';
import './index.css';
import Routers from './router';
import reportWebVitals from './reportWebVitals';
import { createStore } from 'redux';
import { allReducers } from 'src/store/reducer';
import { Provider } from 'react-redux';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import swDev from './swDev';

let store = createStore(allReducers, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
ReactDOM.render(
  <Provider store={store}>
    <React.StrictMode>
      <Routers />
    </React.StrictMode>
  </Provider>,
  document.getElementById('root')
);
reportWebVitals();
swDev();
// Change from unregister() to register() to enable PWA
serviceWorkerRegistration.register();
