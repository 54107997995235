/** @format */

import React, { useState } from 'react';
import { Drawer, Button } from 'antd';
import { MobileView } from 'react-device-detect';
import { NavigationItems } from 'src/config';
import { Link } from 'react-router-dom';
import { LogoutOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import { useAuth } from 'src/contexts/AuthContext';

const MobileNav = () => {
  const { logout } = useAuth();
  const history = useHistory();
  const currentUrl = history.location.pathname;
  const [visible, setVisible] = useState(false);

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };
  return (
    <>
      <MobileView className="mobile-view">
        <ul className="mobile-nav">
          {NavigationItems.map((item, i) => {
            return (
              <Link to={item.url} className={`${currentUrl === item.url ? `active` : ``}`} key={i}>
                <item.icon />
              </Link>
            );
          })}
          <a onClick={showDrawer} key={'logout'}>
            <LogoutOutlined />
          </a>
        </ul>

        <Drawer title="ข้อมูลผู้ใช้งาน" placement="right" onClose={onClose} visible={visible}>
          <a onClick={() => logout()}>ออกจากระบบ</a>
        </Drawer>
      </MobileView>
    </>
  );
};

export default MobileNav;
