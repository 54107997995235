/** @format */

import { css } from '@emotion/css';
export const styles = {
  calWrapper: css`
    display: flex;
    justify-content: center;
  `,
  cal: css`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 15px;
    grid-column-gap: 10px;
    max-width: 475px;
    & button {
      background: #333333;
      color: #fff;
      font-size: 1.5rem;
      border: none;
      border-radius: 70%;
      cursor: pointer;
      height: 65px;
      width: 65px;
    }

    button:active,
    button:focus {
      filter: brightness(120%);
      background-color: #fec576;
      color: #333333;
    }
    & .key-zero {
      grid-column: span 2;
      width: 100%;
      border-radius: 30px;
    }

    .key-operate {
      background: #ff9501;
      span {
        pointer-events: none;
      }
    }

    .key-others {
      background: #a6a6a6;
      color: #000000;
      span {
        pointer-events: none;
      }
    }
  `,
  showRate: css`
    display: flex;
    flex-direction: column;
    padding: 24px;
    max-width: 475px;
    margin: 0 auto;
    .rate-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px 0;
      .cur {
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        background-color: #ff9800;
        color: #333333;
        padding: 12px;
        border-radius: 999px;
      }
      .result {
        font-size: 28px;
        font-weight: bold;
      }
      &:last-child {
        .result {
          color: #508d4e;
        }
      }
    }
  `,
  title: css`
    padding: 12px 24px;
    color: gray;
    font-size: 12px;
    display: block;
  `,
  curOverlay: css`
    ul {
      background-color: #333;
      border-radius: 12px;

      li {
        padding: 12px 24px;
        border-radius: 12px;
        span.ant-dropdown-menu-title-content {
          font-size: 18px;
          text-transform: uppercase;
          color: #fff;
        }
        &:hover {
          background-color: #828282;
        }
      }
    }
  `
};
