/** @format */

import React from 'react';
import Layout from 'src/components/Layout';
import { PageHeader } from 'antd';
import MonthlRecord from './MonthlyRecord';

const Reports = props => {
  return (
    <Layout pageTitle="Reports">
      <PageHeader className="site-page-header" title="Reports" subTitle="reporting " />
      <MonthlRecord />
    </Layout>
  );
};
export default Reports;
