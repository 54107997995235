/** @format */

import React, { useEffect, useState } from 'react';
import {
  Table,
  Button,
  Cascader,
  DatePicker,
  Input,
  InputNumber,
  Popconfirm,
  Form,
  Typography,
  Space,
  Switch
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import Summary from 'src/components/Summary';
import { EditFilled, DeleteFilled, SaveFilled, CloseCircleFilled } from '@ant-design/icons';
import { allAcitionOption, actionOption } from 'src/config';
import { useDB } from 'src/contexts/DBContext';
import NumberFormat from 'react-number-format';

const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
  const inputNode = inputType => {
    if (inputType === 'select') {
      return <Cascader options={actionOption} />;
    }
    if (inputType === 'number') {
      return <InputNumber />;
    }
    return <Input />;
  };

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`
            }
          ]}>
          {inputNode(inputType)}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const EditDataTable = props => {
  const { getData, deleteData, updateData, addData } = useDB();
  const dataFromState = useSelector(stage => stage.getLists);
  const usuallyList = useSelector(stage => stage.getUsuallyList);
  //table form
  const [form] = Form.useForm();
  const [data, setData] = useState(null);
  const [editingKey, setEditingKey] = useState('');

  useEffect(() => {
    handleSetData(dataFromState);
  }, [dataFromState]);

  const handleAddUsualList = async datalist => {
    for (let i = 0; i < datalist.length; i++) {
      let data = {
        list: datalist[i].list,
        action: datalist[i].action,
        create_date: moment().format('YYYY-MM-DD, H:mm'),
        create_month: moment(props.target).format('YYYY-MM'),
        update_date: moment(props.target).startOf('month').format('YYYY-MM-DD, H:mm'),
        amount: datalist[i].amount,
        status: false
      };
      addData('lists', data);
    }

    //  datalist.map(values =>{
    // let data = {
    //   list: values.title,
    //   action: values.catagory,
    //   create_date: moment().format('YYYY-MM-DD, H:mm'),
    //   create_month: moment(props.target).format('YYYY-MM'),
    //   update_date: moment(props.target).startOf('month').format('YYYY-MM-DD, H:mm'),
    //   amount: values.amount,
    //   status:values.status || false
    // };
    //     addData('lists', data);

    // })
  };
  const handleSetData = async data => {
    let newData = [];
    await data?.map(item => {
      if (item.create_month === props.target) {
        newData.push(item);
      }
      if (props.target === 'all') {
        newData.push(item);
      }
    });
    setData(newData);
  };
  const deleteList = item => {
    deleteData('lists', item);
  };

  const isEditing = record => record.key === editingKey;

  const edit = record => {
    form.setFieldsValue({
      date: '',
      list: '',
      action: '',
      amount: '',
      ...record
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async key => {
    try {
      const row = await form.validateFields();
      updateData('lists', key, row);
      setEditingKey('');
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const handleChangeStatus = (status, data) => {
    let newStatus = { ...data, status: status };
    try {
      updateData('lists', data.key, newStatus);
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const columns = [
    {
      title: 'วันที่',
      dataIndex: 'update_date',
      width: '25%',
      editable: true,
      defaultSortOrder: 'descend',
      sorter: (a, b) => moment(a.update_date) - moment(b.update_date)
    },
    {
      title: 'รายการ',
      dataIndex: 'list',
      width: '30%',
      editable: true
    },
    {
      title: 'วิธี',
      dataIndex: 'action',
      width: '15%',
      editable: true,
      render: (action, record) => {
        return action.map(item => {
          return allAcitionOption.map(card => {
            if (item === card.value) {
              return <span style={{ color: `${record.status ? 'green' : ''}` }}>{card.label + '  '}</span>;
            }
          });
        });
      }
    },
    {
      title: 'จำนวน /บาท',
      dataIndex: 'amount',
      width: '10%',
      editable: true,
      sorter: (a, b) => a.amount - b.amount,
      render: (text, record) => {
        if (record.action[0] === 'pay') {
          return (
            <span style={{ color: 'red' }}>
              <NumberFormat value={record.amount} displayType={'text'} thousandSeparator={true} />
            </span>
          );
        }
        if (record.action[0] === 'income') {
          return (
            <span style={{ color: 'green' }}>
              <NumberFormat value={record.amount} displayType={'text'} thousandSeparator={true} />
            </span>
          );
        }
        if (record.action[0] === 'paycard') {
          return (
            <span style={{ color: 'orange' }}>
              <NumberFormat value={record.amount} displayType={'text'} thousandSeparator={true} />
            </span>
          );
        }

        return (
          <span style={{ color: 'red' }}>
            <NumberFormat value={record.amount} displayType={'text'} thousandSeparator={true} />
          </span>
        );
      }
    },
    {
      title: 'สถานะ',
      dataIndex: 'status',
      sorter: (a, b) => a.status - b.status,
      render: (_, record) => {
        return (
          <>
            <Switch checked={record.status} onChange={e => handleChangeStatus(e, record)} />
          </>
        );
      }
    },
    {
      title: 'จัดการ',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <a
              href="javascript:;"
              onClick={() => save(record.key)}
              style={{
                marginRight: 8
              }}>
              <SaveFilled />
            </a>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>
                <CloseCircleFilled />
              </a>
            </Popconfirm>
          </span>
        ) : (
          <Space>
            <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
              <EditFilled />
            </Typography.Link>
            <Popconfirm disabled={editingKey !== ''} title="Sure to delete?" onConfirm={() => deleteList(record.key)}>
              <a disabled={editingKey !== ''}>
                <DeleteFilled />
              </a>
            </Popconfirm>
          </Space>
        );
      }
    }
  ];
  function checkInputType(dataIndex) {
    if (dataIndex === 'amount') {
      return 'number';
    }
    if (dataIndex === 'action') {
      return 'select';
    }
    if (dataIndex === 'update_date') {
      return 'date';
    }
    return 'text';
  }
  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: record => ({
        record,
        inputType: checkInputType(col.dataIndex),
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record)
      })
    };
  });

  return (
    <>
      <Button type="primary" onClick={() => handleSetData(dataFromState)}>
        Fetch
      </Button>
      <Button type="primary" className="ml-2" onClick={() => handleAddUsualList(usuallyList)}>
        Add Usually List
      </Button>
      <Summary target={props.target} data={data} />

      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell
            }
          }}
          loading={data ? false : true}
          bordered
          row={30}
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{ pageSize: 50 }}
        />
      </Form>
    </>
  );
};

export default EditDataTable;
