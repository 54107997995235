/** @format */

import React, { useEffect, useState } from 'react';
import { Table, Button, Input, InputNumber, Popconfirm, Form, Typography, Space } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useDB } from 'src/contexts/DBContext';

const EditableCell = ({ editing, dataIndex, title, inputType, record, index, children, ...restProps }) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`
            }
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const Income = () => {
  const { updateData, deleteData, addData } = useDB();

  const dataFromState = useSelector(stage => stage.getSetting);
  //table form
  const [form] = Form.useForm();
  const [data, setData] = useState(dataFromState);
  const [editingKey, setEditingKey] = useState('');

  useEffect(() => {
    setData(dataFromState);
  }, [dataFromState]);

  const isEditing = record => record.key === editingKey;

  const edit = record => {
    form.setFieldsValue({
      title: '',
      amoutn: '',
      ...record
    });
    setEditingKey(record.key);
  };
  const handleAdd = async () => {
    let newData = {
      title: 'กรุณากดแกไข เพื่อเพิ่มรายการ',
      amount: '0'
    };
    await addData('setting', newData);
  };
  const cancel = () => {
    setEditingKey('');
  };
  const deleteList = key => {
    try {
      deleteData('setting', key);
    } catch (err) {
      console.log(err);
    }
  };
  const save = async key => {
    try {
      const row = await form.validateFields();
      updateData('setting', key, row);
      setEditingKey('');
    } catch (errInfo) {
      console.log('Validate Failed:', errInfo);
    }
  };

  const columns = [
    {
      title: 'รายการ',
      dataIndex: 'title',
      editable: true
    },
    {
      title: 'จำนวน /บาท',
      dataIndex: 'amount',
      width: '15%',
      editable: true
    },
    {
      title: 'จัดการ',
      width: '10%',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <a
              href="javascript:;"
              onClick={() => save(record.key)}
              style={{
                marginRight: 8
              }}
            >
              Save
            </a>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Space>
            <Typography.Link disabled={editingKey !== ''} onClick={() => edit(record)}>
              Edit
            </Typography.Link>
            <Popconfirm title="Sure to delete?" onConfirm={() => deleteList(record.key)}>
              <a>Delete</a>
            </Popconfirm>
          </Space>
        );
      }
    }
  ];
  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: record => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record)
      })
    };
  });
  return (
    <>
      <Button type="primary" onClick={handleAdd}>
        เพิ่ม
      </Button>
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell
            }
          }}
          bordered
          dataSource={data}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel
          }}
        />
      </Form>
    </>
  );
};

export default Income;
