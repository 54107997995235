/** @format */

import React from 'react';
import Layout from 'src/components/Layout';
import { Tabs, PageHeader } from 'antd';
import Income from './Income';
import {UsuallyList} from './UsuallyList'
const { TabPane } = Tabs;
function callback(key) {
  console.log(key);
}
const Setting = props => {
  return (
    <Layout pageTitle="Setting">
      <PageHeader className="site-page-header" title="Setting" />
      <Tabs type="card" defaultActiveKey="1" onChange={callback}>
        <TabPane tab="รายการประจำ" key="1">
          <UsuallyList />
        </TabPane>
        <TabPane tab="คลัง" key="2">
          <Income />
        </TabPane>
      </Tabs>
    </Layout>
  );
};
export default Setting;
