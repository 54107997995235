/** @format */

const GetSettingAction = data => {
  return {
    type: 'GETSETTING',
    playload: data
  };
};

export { GetSettingAction };
