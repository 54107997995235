/** @format */

import React from 'react';
import { Fragment, useState, useEffect } from 'react';
import {
  ArrowLeftOutlined,
  SwapOutlined,
  CloseOutlined,
  MinusOutlined,
  PlusOutlined,
  DownOutlined
} from '@ant-design/icons';
import axios from 'axios';
import { Button, Dropdown, Space } from 'antd';
import { styles } from './styles';
import NumberFormat from 'react-number-format';

export default function Calculator() {
  const [allData, setAllData] = useState(null);
  const [currency, setCurrency] = useState(0);
  const [base, setBase] = useState('thb');
  const [compare, setCompare] = useState('jpy');
  const [calculate, setCalculate] = useState('0');
  const [money, setMoney] = useState('0');
  const [operator, setOperator] = useState(false);
  const fetchCurrencyData = async () => {
    try {
      const res = await axios.get(
        `https://cdn.jsdelivr.net/npm/@fawazahmed0/currency-api@latest/v1/currencies/${base}.json`
      );

      const data = res.data;
      const value = data[base][compare];
      setAllData(data);
      setCurrency(value);
    } catch (err) {
      console.error(`Error fetching API: ${err}`);
    }
  };
  useEffect(() => {
    fetchCurrencyData();
    return fetchCurrencyData;
  }, [base, compare]);

  useEffect(() => {
    setCalculate(Number(money) * Number(currency));
  }, [money, currency]);

  function isNotNumber(input) {
    return isNaN(parseFloat(input)) || !isFinite(input);
  }

  function handleClick(e) {
    let value = e.target.value;

    if (!isNotNumber(value) || value === '.') {
      let newNumber = money.charAt(0) !== '0' || value === '.' ? money + value : value;
      setMoney(newNumber);
    }
    switch (value) {
      case 'c':
        setMoney('0');
        setCalculate('0');
        break;
      case 'd':
        let Money = money;
        let newMoney = Money.substring(0, money.length - 1) || '0';
        setMoney(newMoney);
        break;
      case 's':
        let b = base;
        let c = compare;
        setBase(c);
        setCompare(b);
        break;
      case '+':
        setOperator(true);
        let p = money + '+';
        setMoney(p);
        break;
      case '-':
        setOperator(true);
        let m = money + '-';
        setMoney(m);
        break;
      case '*':
        setOperator(true);
        let t = money + '*';
        setMoney(t);
        break;
      case '/':
        setOperator(true);
        let d = money + '/';
        setMoney(d);
        break;
      case '=':
        setOperator(false);
        let e = eval(money);
        setMoney(e.toString());
        break;
      default:
        break;
    }
  }
  const itemsBase = [
    { label: <span onClick={() => setBase('usd')}>usd</span> },
    { label: <span onClick={() => setBase('jpy')}>jpy</span> },
    { label: <span onClick={() => setBase('thb')}>thb</span> },
    { label: <span onClick={() => setBase('eur')}>eur</span> }
  ];
  const itemsCompare = [
    { label: <span onClick={() => setCompare('usd')}>usd</span> },
    { label: <span onClick={() => setCompare('jpy')}>jpy</span> },
    { label: <span onClick={() => setCompare('thb')}>thb</span> },
    { label: <span onClick={() => setCompare('eur')}>eur</span> }
  ];
  return (
    <Fragment>
      <Space>
        <Button onClick={() => fetchCurrencyData()}>Refresh</Button>
        <span className={styles.title}>Updated : {allData?.date}</span>
      </Space>

      <div className={styles.showRate}>
        <div className="rate-item">
          <Dropdown
            className="cur"
            overlayClassName={styles.curOverlay}
            menu={{
              items: itemsBase
            }}
            trigger={['click']}>
            <a onClick={e => e.preventDefault()}>
              <Space>
                {base}
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>

          <span className="result">
            {operator ? (
              money
            ) : (
              <NumberFormat value={money} displayType={'text'} fixedDecimalScale={true} thousandSeparator={true} />
            )}
          </span>
        </div>
        <div className="rate-item">
          <Dropdown
            className="cur"
            overlayClassName={styles.curOverlay}
            menu={{
              items: itemsCompare
            }}
            trigger={['click']}>
            <a onClick={e => e.preventDefault()}>
              <Space>
                {compare}
                <DownOutlined />
              </Space>
            </a>
          </Dropdown>

          <span className="result">
            {
              <NumberFormat
                value={calculate == 0 || isNaN(calculate) ? 0 : calculate}
                displayType={'text'}
                decimalScale={2}
                fixedDecimalScale={true}
                thousandSeparator={true}
              />
            }
          </span>
        </div>
      </div>
      <div className={styles.calWrapper}>
        <div className={styles.cal}>
          <button type="button" value="c" onClick={handleClick} className="key-others operations">
            C
          </button>
          <button type="button" value="d" onClick={handleClick} className="key-others operations">
            <ArrowLeftOutlined />
          </button>
          <button type="button" value="s" onClick={handleClick} className="key-others operations">
            <SwapOutlined />
          </button>
          <button type="button" value="/" onClick={handleClick} className="key-operate operations">
            /
          </button>
          <button type="button" onClick={handleClick} value={7} className="numbers">
            7
          </button>
          <button type="button" value="8" onClick={handleClick} className="numbers">
            8
          </button>
          <button type="button" value="9" onClick={handleClick} className="numbers">
            9
          </button>
          <button type="button" value="*" onClick={handleClick} className="key-operate operations">
            <CloseOutlined />
          </button>
          <button type="button" value="4" onClick={handleClick} className="numbers">
            4
          </button>
          <button type="button" value="5" onClick={handleClick} className="numbers">
            5
          </button>
          <button type="button" value="6" onClick={handleClick} className="numbers">
            6
          </button>
          <button type="button" value="-" onClick={handleClick} className="key-operate operations">
            <MinusOutlined />
          </button>
          <button type="button" value="1" onClick={handleClick} className="numbers">
            1
          </button>
          <button type="button" value="2" onClick={handleClick} className="numbers">
            2
          </button>
          <button type="button" value="3" onClick={handleClick} className="numbers">
            3
          </button>
          <button type="button" value="+" onClick={handleClick} className="key-operate operations">
            <PlusOutlined />
          </button>
          <button type="button" value="0" onClick={handleClick} className="key-zero numbers">
            0
          </button>
          <button type="button" value="." onClick={handleClick} className="numbers">
            .
          </button>
          <button type="button" value="=" onClick={handleClick} className="key-operate operations">
            =
          </button>
        </div>
      </div>
    </Fragment>
  );
}
