/** @format */

import React from 'react';
import Layout from 'src/components/Layout';
import { PageHeader } from 'antd';
import Calculator from 'src/components/calculator/Calculator';

const Exchange = props => {
  return (
    <Layout pageTitle="Exchange Rate">
      <PageHeader className="site-page-header" title="Exchange Rate" subTitle="currency " />

      <Calculator />
    </Layout>
  );
};
export default Exchange;
