/** @format */

const getUsuallyListReducer = (state = null, action) => {
    switch (action.type) {
      case 'GETUSUALLYLIST':
        return (state = action.playload);
      default:
        return state;
    }
  };
  export default getUsuallyListReducer;
  