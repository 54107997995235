/** @format */

import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Menu, Drawer } from 'antd';
import { NavigationItems } from 'src/config';
import { LogoutOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useAuth } from 'src/contexts/AuthContext';
import { useHistory } from 'react-router';
import { BrowserView } from 'react-device-detect';
const { SubMenu } = Menu;
const Header = props => {
  const [visible, setVisible] = useState(false);
  const history = useHistory();
  const [current, setCurrent] = useState(history.location.pathname);
  const { logout } = useAuth();
  const handleClick = e => {
    setCurrent(e.key);
  };
  return (
    <>
      <Helmet>
        <title>{props.pageTitle}</title>
      </Helmet>
      <BrowserView>
        <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal">
          {NavigationItems.map((item, i) => {
            return (
              <Menu.Item key={item.key} icon={<item.icon />} hidden={!item.active}>
                <Link to={item.url} key={i}>
                  {item.title}
                </Link>
              </Menu.Item>
            );
          })}
          <Menu.Item className="logout" key={'logout'} icon={<LogoutOutlined />}>
            <a
              onClick={async () => {
                try {
                  await logout();
                } catch (err) {
                  console.log(err);
                }
              }}
            >
              Logout
            </a>
          </Menu.Item>
        </Menu>
      </BrowserView>
    </>
  );
};

export default Header;
