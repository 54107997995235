/** @format */

const GetListsAction = data => {
  return {
    type: 'GETLISTS',
    playload: data
  };
};

export { GetListsAction };
