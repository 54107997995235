/** @format */

import React, { Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { DBProvider } from './contexts/DBContext';

// views
import Dashboard from 'src/views/dashboard';
import Login from 'src/views/login';
import Recording from 'src/views/recording';
import Reports from './views/reports';
import Setting from './views/setting';
import Exchange from './views/exchange';
const routes = [
  {
    path: '/dashboard',
    component: Dashboard,
    exact: true
  },
  {
    path: '/recording',
    component: Recording,
    exact: true
  },
  {
    path: '/reports',
    component: Reports,
    exact: true
  },
  {
    path: '/exchange',
    component: Exchange,
    exact: true
  },
  {
    path: '/setting',
    component: Setting,
    exact: true
  },
  {
    path: '/',
    component: Login,
    exact: true
  },
  {
    path: '/logout',
    component: () => <Login />,
    exact: true
  }
];

const Routers = props => {
  return (
    <AuthProvider>
      <DBProvider>
        <Router fallback={() => <h1>Loading.....</h1>}>
          <Switch>
            {routes.map((route, key) => {
              const Layout = route.layout || Fragment;
              const Component = route.component;

              if (route.from || route.to) {
                return <Redirect key={key} exact={route.exact} from={route.from} to={route.to} />;
              }
              return (
                <Route key={key} path={route.path} exact={route.exact}>
                  <Layout>
                    <Component {...props} />
                  </Layout>
                </Route>
              );
            })}
            <Route path="*" component={() => 'error'} />
          </Switch>
        </Router>
      </DBProvider>
    </AuthProvider>
  );
};
export default Routers;
