/** @format */

import {
  DashboardOutlined,
  EditOutlined,
  SettingOutlined,
  LogoutOutlined,
  PieChartOutlined,
  BankOutlined
} from '@ant-design/icons';
import citiReady from 'src/assets/bank_barcode/citi-ready.png';
import citiVisa from 'src/assets/bank_barcode/citi-visa.png';
export const NavigationItems = [
  {
    title: 'Dashboard',
    key: '/dashboard',
    icon: DashboardOutlined,
    url: '/dashboard',
    active: true
  },
  {
    title: 'Recording',
    key: '/recording',
    icon: EditOutlined,
    url: '/recording',
    active: true
  },
  // {
  //   title: 'Reports',
  //   key: '/reports',
  //   icon: PieChartOutlined,
  //   url: '/reports',
  //   active: true
  // },
  {
    title: 'Exchange',
    key: '/exchange',
    icon: BankOutlined,
    url: '/exchange',
    active: true
  },
  {
    title: 'Setting',
    key: '/setting',
    icon: SettingOutlined,
    url: '/setting',
    active: true
  }
];

export const creditCard = [
  {
    value: 'Citi',
    label: 'Citi visa'
  },
  {
    value: 'CitiCash',
    label: 'Citi Cash'
  },
  {
    value: 'KTCVisa',
    label: 'KTC visa'
  },
  {
    value: 'KTCCash',
    label: 'KTC Cash'
  },
  {
    value: 'FirstChoice',
    label: 'First Choice'
  }
];
const payDebt = [
  {
    value: 'paydebt',
    label: 'ชำระบัตร',
    children: creditCard
  }
];
export const actionOption = [
  {
    value: 'income',
    label: 'รายรับ'
  },
  {
    value: 'pay',
    label: 'รายจ่าย'
  },
  {
    value: 'paycard',
    label: 'จ่ายผ่านบัตร',
    children: creditCard
  },
  {
    value: 'paydebt',
    label: 'ชำระบัตร',
    children: creditCard
  },
  {
    value: 'getdebt',
    label: 'กดเงินสด',
    children: creditCard
  }
];

export const allAcitionOption = actionOption.concat(creditCard, payDebt);

export const payBarcode = [
  { title: 'citi visa', value: citiVisa },
  { title: 'citi Ready', value: citiReady }
];
