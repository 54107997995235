/** @format */

import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 1rem;
  @media (min-width: 768px) {
    padding: 2rem 3rem;
  }
`;
