/** @format */

import React, { useEffect } from 'react';
import Layout from 'src/components/Layout';
import { PageHeader } from 'antd';
import FormAdd from 'src/components/FormAdd';
import { Container } from 'src/components/utils/Container';
import  MonthlRecord  from 'src/views/reports/MonthlyRecord';
import moment from 'moment';
const Recording = props => {
  return (
    <Layout pageTitle="Recording">
      <PageHeader className="site-page-header" title="Recording" subTitle="Record your Dabit and Cradit dayly." />
      <Container>
        <FormAdd />
        <MonthlRecord />
      </Container>
    </Layout>
  );
};
export default Recording;
