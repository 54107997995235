/** @format */

import React from 'react';
import './style.scss';
import Header from 'src/components/Header';
import styled from 'styled-components';
import MobileNav from 'src/components/MobileNav';
import { useAuth } from 'src/contexts/AuthContext';
import { useHistory } from 'react-router';

const Layout = props => {
  const { currentUser } = useAuth();
  const history = useHistory();
  let Container = styled.section`
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
  `;
  return (
    <>
      {currentUser ? (
        <Container>
          <Header pageTitle={props.pageTitle} />
          {props.children}
          <MobileNav />
        </Container>
      ) : (
        history.push('/')
      )}
    </>
  );
};

export default Layout;
