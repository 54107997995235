import React, {Fragment, useEffect, useState } from 'react';
import { Form, Input, Button, Cascader, DatePicker, InputNumber, Card, notification, Switch, Table,Popconfirm,  Typography, Space } from 'antd';
import { actionOption, payBarcode } from 'src/config';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { useDB } from 'src/contexts/DBContext';

import GetUsualList from './GetUsualList';

export const UsuallyList = props =>{
  const { addData } = useDB();

  const [addList] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [date, setDate] = useState(moment());
  const onDateChange = (date, dateString) => {
    setDate(date);
  };
  const onReset = () => {
    addList.resetFields();
  };

  const onFinish = async values => {
    setLoading(true);
    let data = {
      list: values.title,
      action: values.catagory,
      amount: values.amount,
      status:values.status || false
    };
    try {
      let addData_list = await addData('usually_list', data);
      if (addData_list === 1) {
        onReset();
        setLoading(false);
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      <Card title="บันทักรายรับ รายจ่าย" loading={loading} className="record-box">
        <Form
          labelCol={{
            span: 4
          }}
          wrapperCol={{
            span: 14
          }}
          layout="horizontal"
          size={'large'}
          form={addList}
          onFinish={onFinish}
        >
          <Form.Item
            label="รายการบันทึก"
            name="title"
            rules={[
              {
                required: true,
                message: 'ใส่ข้อความ'
              }
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="หมวดหมู่"
            name="catagory"
            rules={[
              {
                required: true,
                message: 'ใส่ข้อความ'
              }
            ]}
          >
            <Cascader options={actionOption} />
          </Form.Item>
          <Form.Item
            label="จำนวน"
            name="amount"
            rules={[
              {
                required: true,
                message: 'ใส่ข้อความ'
              }
            ]}
          >
            <InputNumber style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item
            label="สถานะ"
            name="status"
           
          >
            <Switch/>
          </Form.Item>
          <Form.Item label="กด">
            <Button htmlType="submit" type="primary" block>
              บันทึก
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <Card>
      <GetUsualList />
      </Card>
     
    </>
  );
};
