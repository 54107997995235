/** @format */

import React from 'react';
import Layout from 'src/components/Layout';
import moment from 'moment';
import { Button, Tabs } from 'antd';
import EditDataTable from 'src/components/EditDataTable';

const { TabPane } = Tabs;

const MonthlRecord = props => {
  function callback(key) {}
  return (
    <>
    <Tabs type="card" defaultActiveKey="2" onChange={callback}>
      <TabPane tab={moment().add(-1, 'Month').format('MMMM YYYY')} key="1">
        <EditDataTable target={moment().add(-1, 'Month').format('YYYY-MM')} />
      </TabPane>
      <TabPane tab={moment().format('MMMM YYYY') + `| ปัจจุบัน`} key="2">
        <EditDataTable target={moment().format('YYYY-MM')} />
      </TabPane>
      <TabPane tab={moment().add(1, 'Month').format('MMMM YYYY')} key="3">
        <EditDataTable target={moment().add(1, 'Month').format('YYYY-MM')} />
      </TabPane>
      <TabPane tab={moment().add(2, 'Month').format('MMMM YYYY')} key="4">
        <EditDataTable target={moment().add(2, 'Month').format('YYYY-MM')} />
      </TabPane>
      <TabPane tab={moment().add(3, 'Month').format('MMMM YYYY')} key="5">
        <EditDataTable target={moment().add(3, 'Month').format('YYYY-MM')} />
      </TabPane>
      <TabPane tab={moment().add(4, 'Month').format('MMMM YYYY')} key="6">
        <EditDataTable target={moment().add(4, 'Month').format('YYYY-MM')} />
      </TabPane>
      <TabPane tab={moment().add(5, 'Month').format('MMMM YYYY')} key="7">
        <EditDataTable target={moment().add(5, 'Month').format('YYYY-MM')} />
      </TabPane>
      <TabPane tab={moment().add(6, 'Month').format('MMMM YYYY')} key="8">
        <EditDataTable target={moment().add(6, 'Month').format('YYYY-MM')} />
      </TabPane>
      <TabPane tab={moment().add(7, 'Month').format('MMMM YYYY')} key="9">
        <EditDataTable target={moment().add(7, 'Month').format('YYYY-MM')} />
      </TabPane>
      <TabPane tab={moment().add(8, 'Month').format('MMMM YYYY')} key="10">
        <EditDataTable target={moment().add(8, 'Month').format('YYYY-MM')} />
      </TabPane>
      <TabPane tab={moment().add(9, 'Month').format('MMMM YYYY')} key="11">
        <EditDataTable target={moment().add(9, 'Month').format('YYYY-MM')} />
      </TabPane>
      <TabPane tab={moment().add(10, 'Month').format('MMMM YYYY')} key="12">
        <EditDataTable target={moment().add(10, 'Month').format('YYYY-MM')} />
      </TabPane>
      <TabPane tab={moment().add(11, 'Month').format('MMMM YYYY')} key="13">
        <EditDataTable target={moment().add(11, 'Month').format('YYYY-MM')} />
      </TabPane>
      <TabPane tab={`ทั้งหมด`} key="8">
        <EditDataTable target={`all`} />
      </TabPane>
    </Tabs>
    </>
  );
};

export default MonthlRecord;
