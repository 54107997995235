/** @format */

import React, { useState, useEffect } from 'react';
import { PageHeader, Button, Descriptions, Table } from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';
import NumberFormat from 'react-number-format';

const Summary = props => {
  const data = useSelector(data => data.getLists);
  const [allTotal, setAllTotal] = useState({});
  const [cradit, setCradit] = useState(null);

  function handleCalculate() {
    let total_income = 0;
    let total_coust = 0;
    let total_citi = 0;
    let total_ktc = 0;
    let paid = 0;
    let unpaid = 0;
    data &&
      data.map(item => {
        if (item.create_month === props.target) {
          if (item.action[0] === 'income') {
            total_income = total_income + parseFloat(item.amount);
          }
          if (item.action[0] === 'pay' || item.action[0] === 'paydebt') {
            total_coust = total_coust + parseFloat(item.amount);
          }
          if (item.status && (item.action[0] === 'pay' || item.action[0] === 'paydebt')) {
            paid = paid + parseFloat(item.amount);
          }
          if (!item.status && (item.action[0] === 'pay' || item.action[0] === 'paydebt')) {
            unpaid = unpaid + parseFloat(item.amount);
          }
          if (item.action[0] === 'paycard') {
            item.action.map(a => {
              if (a === 'Citi') {
                total_citi = total_citi + parseFloat(item.amount);
              }
              if (a === 'KTCVisa') {
                total_ktc = total_ktc + parseFloat(item.amount);
              }
            });
          }
        }
        if (props.target === 'all') {
          if (item.action[0] === 'income') {
            total_income = total_income + parseFloat(item.amount);
          }
          if (item.action[0] === 'pay' || item.action[0] === 'paydebt') {
            total_coust = total_coust + parseFloat(item.amount);
          }
          if (item.action[0] === 'paycard') {
            item.action.map(a => {
              if (a === 'Citi') {
                total_citi = total_citi + parseFloat(item.amount);
              }
              if (a === 'KTCVisa') {
                total_ktc = total_ktc + parseFloat(item.amount);
              }
            });
          }
        }
      });
    setAllTotal({
      income: total_income,
      coust: total_coust,
      citiCard: total_citi,
      ktcCard: total_ktc,
      paid,
      unpaid
    });
  }

  useEffect(() => {
    handleCalculate();
  }, [data]);
  const columns = [
    {
      title: 'รายการบัตร',
      dataIndex: ''
    },
    {
      title: 'วงเงิน',
      dataIndex: ''
    },
    {
      title: 'ใช้ไป',
      dataIndex: ''
    },
    {
      title: 'คงเหลือ',
      dataIndex: ''
    }
  ];

  return (
    <div
      className="site-page-header-ghost-wrapper"
      style={{
        border: '1px solid #f0f0f0',
        borderBottom: 'none'
      }}>
      <PageHeader
        ghost={false}
        title={`สรุปรายงานประจำเดือน `}
        subTitle={`${moment(props.target).format('MMMM YYYY')}`}>
        <Descriptions size="middle" column={3}>
          <Descriptions.Item label="รายรับ">
            <NumberFormat value={allTotal.income} displayType={'text'} thousandSeparator={true} />
          </Descriptions.Item>
          <Descriptions.Item label="รายจ่าย">
            <NumberFormat value={allTotal.coust} displayType={'text'} thousandSeparator={true} />
          </Descriptions.Item>
          <Descriptions.Item label="คงเหลือ">
            <NumberFormat value={allTotal.income - allTotal.coust} displayType={'text'} thousandSeparator={true} />
          </Descriptions.Item>
          <Descriptions.Item label="จ่ายไปแล้ว">
            <NumberFormat value={allTotal.paid} displayType={'text'} thousandSeparator={true} />
          </Descriptions.Item>
          <Descriptions.Item label="ค้างจ่าย">
            <NumberFormat value={allTotal.unpaid} displayType={'text'} thousandSeparator={true} />
          </Descriptions.Item>
        </Descriptions>
        <p>บัตรเครติด : </p>
        <Descriptions size="middle" column={3}>
          <Descriptions.Item label="Citi">{allTotal.citiCard}</Descriptions.Item>
          <Descriptions.Item label="KTC visa">{allTotal.ktcCard}</Descriptions.Item>
        </Descriptions>
      </PageHeader>
    </div>
  );
};

export default Summary;
